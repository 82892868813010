import paymentsMethods from '@Enums/paymentsMethods';

export const useMoney = () => {
  const { $i18n } = useNuxtApp();

  const format_money: any = (value: number | any) => {
    if (typeof value !== 'number') {
      return setCurrency(parseFloat(value));
    }
    return setCurrency(value);
  };

  const format_money_without_PLN: any = (value: number | any) => {
    if (typeof value !== 'number') {
      return value;
    }
    return setCurrencyWithoutPLN(value);
  };

  const setCurrency: any = (value: number | any) => {
    const newValue = setValueWithMoneyFormat(value);
    return `${newValue} zł`;
  };

  const setCurrencyWithoutPLN: any = (value: any) => {
    const newValue = setValueWithMoneyFormat(value);
    return `${newValue}` as string;
  };

  const setValueWithMoneyFormat: any = (amount: any, decimalCount = 2, decimal = ',', thousands = ' ') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      let i: any = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      const valueOverThousand = `${negativeSign}${j ? i.substr(0, j) + thousands : ''}${i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)}`;
      const valueBelowThousand = decimalCount
        ? `${
            decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          }`
        : '';
      return `${valueOverThousand}${valueBelowThousand}`;
    } catch (e) {
      console.warn(e);
    }
  };

  const translatePaymentWay = (paymentWayToTranslate: string) => {
    const paymentsWay: { [key: string]: string } = {
      [paymentsMethods.TRANSFER]: $i18n.t('paymentsMethods.transfer'),
      [paymentsMethods.NET_TRANSFER]: $i18n.t('paymentsMethods.online'),
      [paymentsMethods.BAILIFF_OFFICE]: $i18n.t('paymentsMethods.office'),
      [paymentsMethods.LOWERED]: $i18n.t('marginInfo.loweredConfirmed')
    };
    return paymentsWay[paymentWayToTranslate] || '';
  };

  return {
    format_money,
    format_money_without_PLN,
    translatePaymentWay
  };
};
